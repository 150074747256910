import React from "react"
import Layout from "./src/components/Layout"

const transitionDelay = 500

export const wrapPageElement = ({ element, props }) => {
  return (
    <Layout {...props}>{element}</Layout>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false;
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.pathname)
  window.previousPath = window.locations[window.locations.length - 2]
  if (prevLocation) {
    console.log(prevLocation.pathname + prevLocation.search)
  }
  if (location.hash) {
    let element = document.getElementById(location.hash.substr(1))
    if(element) {
      let hashTop = element.offsetTop
      window.setTimeout(
        () => window.scrollTo(0,hashTop),
        10
      )
    }
  }
}