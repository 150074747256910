import React, {memo} from "react"
import "./style.scss"
import { motion } from "framer-motion"

import animationData from "../../DATA/animationData"

import { graphql, useStaticQuery } from "gatsby"

function SocialIcons() {

	const data = useStaticQuery(graphql`
		{
			contentfulGeneral(title: {eq: "1/ST"}) {
				id
				socialLinks {
					title
					id
					url
					icon
				}
			}
		}
	`)

  	const socialIcons = data.contentfulGeneral.socialLinks.map(prepNavi)

	function prepNavi(item, index) {
		return (
			<motion.li key={item.id} variants={animationData.fadeInUpFaster} className="social-icon"><a href={item.url} target="_blank" rel="noopener noreferrer"><i className={"fab fa-" + item.icon}></i></a></motion.li>
		)
	}

	return (
		<motion.ul variants={animationData.stagger} className="social-icons">
			{socialIcons}
		</motion.ul>
	)
}

export default memo(SocialIcons)