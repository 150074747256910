import React, {memo} from "react"
import './style.scss';
import { useInView } from 'react-intersection-observer'
import { motion } from "framer-motion"


function Racetrack(props) {
  const [ref, inView] = useInView({ triggerOnce: true })

  const icon = {
	hidden: {
	    opacity: 0,
	    pathLength: 0,
	    fill: "#ffffff"
	},
	visible: {
	    opacity: 1,
	    pathLength: 1,
	    fill: "#ffffff"
	}
  }

  return (
	<>
		<motion.svg ref={ref}
	      xmlns="http://www.w3.org/2000/svg"
	      viewBox="0 0 990.01 395.99"
	      className="racetrack"
	    >
	      <motion.path
	        d="M837,707.78H243c-44.76,0-86.89-17.47-118.61-49.19c-31.72-31.72-49.19-73.84-49.19-118.6
			c0-44.76,17.47-86.87,49.2-118.59c31.72-31.72,73.84-49.19,118.6-49.19l594,0.01c44.76,0,86.89,17.47,118.61,49.19
			c31.72,31.72,49.19,73.84,49.19,118.59c0,44.75-17.47,86.87-49.2,118.59C923.88,690.31,881.76,707.78,837,707.78z"
	        variants={icon}
	        initial="hidden"
	        animate={ inView ? "visible" : "hidden" }
		    transform="translate(-45 -342)"
	        transition={{
	          default: { duration: 0.25, ease: "easeIn" },
	        }}
	      />
	    </motion.svg>
	</>
  );
}

export default memo(Racetrack);
