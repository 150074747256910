export const headerData = {
    navigation: [
        {
        id: 1001,
        name: "Home",
        url: "/",
        },
        {
        id: 1002,
        name: "Horse Care",
        url: "/horse-care"
        },
        {
        id: 1003,
        name: "Venues",
        url: "/venues"
        },
        {
        id: 1004,
        name: "Live",
        url: "/live"
        },
        {
        id: 1005,
        name: "1ST Bet",
        url: "/1st-bet"
        },
        {
        id: 1006,
        name: "Technology",
        url: "/tech"
        },
        {
        id: 1007,
        name: "About",
        url: "/about"
        },
        {
        id: 1008,
        name: "Properties",
        url: "/properties"
        },
        {
        id: 1009,
        name: "Download 1/ST bet",
        url: "https://www.1st.com/bet",
        external: true
        },
        {
        id: 1010,
        name: "Contact",
        url: "/contact"
        },
    ],
    logoSrc: "/DATA/IMG/SVG/logo_main_black.svg",
}

export const footerData = {
  navigation: [
    {
        id: 2001,
        name: "Terms & Legal",
        url: "/terms-and-legal"
    },
    {
        id: 2002,
        name: "Privacy Policy",
        url: "/privacy-policy"
    },
    {
        id: 2003,
        name: "Horse Care",
        url: "/horse-care"
    },
    {
        id: 2004,
        name: "Contact Us",
        url: "/contact"
    },
    {
        id: 2005,
        name: "Download 1/ST bet",
        url: "https://www.1st.com/bet",
        external: true
    },
    {
        id: 2006,
        name: "Site",
        url: "https://www.deepsleepstudio.com",
        external: true
    },
  ],
  logoSrc: "/DATA/IMG/SVG/logo_main_white.svg",
}

export const socialLinks = [
    {
        id: 10001,
        icon: "facebook-f",
        url: "http://www.facebook.com/1stracing ",
    },
    {
        id: 10002,
        icon: "twitter",
        url: "http://www.twitter.com/1st_racing",
    },
    {
        id: 10003,
        icon: "instagram",
        url: "http://www.instagram.com/1stracing",
    },
    {
        id: 10004,
        icon: "youtube",
        url: "http://www.youtube.com/1stracing",
    },
]
    
export const pageData = [
    {
        id: 1,
        name: "Homepage",
        slug: "home",
        sections: {
            hero: {
                name: "hero-race",
                subtype: "race",
                content: [
                    {
                        img: "/DATA/IMG/page-home/video_img_homepage_flag@2x.jpg",
                        video: "url",
                        text: "Be Bold<br>Be 1/ST",
                    },
                    {
                        img: "/DATA/IMG/page-horse_care/img_horses_riders.jpg",
                        video: "url",
                        text: "Be Bold<br>Be 1/ST",
                    },
                ]
            },
            imgPlusContent: {
                name: "we-set-the-pace",
                type: "header-img-text-condensed",
                content: {
                    header: "We Set the Pace",
                    text: "<p>1/ST is dedicated to taking an innovative approach toward the Thoroughbred racing industry by elevating our racing, wagering technologies, hospitality and dining experiences, and events to excite and energize our current fans and mobilize new fans to the sport.</p><p>1/ST is dedicated to working at every stable and track within our enterprise to set the gold standard for horse safety and horse care.</p><p>We are reimagining the online wagering experience, whether Deployed at the track, at home or at other venues.  And we are recasting the role entertainment and hospitality plays within our sport.  Underlying these efforts is 1/ST Technology which is focusing on new platforms, systems and processes to make our sport more accessible to future generations, more efficient and more transparent.</p>",
                    mission: [
                        "To reinvent the sport of horse racing and gaming",
                        "achieve a world-class level of safety for horses and riders",
                        "to provide a unique and fully engaging entertainment experience for our guests and online wagerers",
                        "to ensure rewarding and sustainable careers for our employees",
                        "1/ST is dedicated to working at every stable and track within our enterprise to set the gold standard for horse safety and horse care",
                    ],
                    img: "/DATA/IMG/page-horse_care/img_horses_riders.jpg"
                }
            },
            video: {
                name: "we-are-1st",
                content: {
                    video: "url",
                    headline: "We are 1/st<br>1/4"
                }
            },
            venuesSummary: {
                name: "venues-summary",
                intro: {
                    header: "Four<br>Legendary<br>Venues",
                    subHeader: "Visit 1/st venues"
                },
                content: [
                    {
                        name: "Golden Gate Fields",
                        img: "url",
                        logo: "url",
                        color: "#F6D762"
                    },
                    {
                        name: "Santa Anita Park",
                        img: "url",
                        logo: "url",
                        color: "#05F3B0"
                    },
                    {
                        name: "Gulfstream Park",
                        img: "url",
                        logo: "url",
                        color: "#31CDEA"
                    },
                    {
                        name: "Golden Gate Fields",
                        img: "url",
                        logo: "url",
                        color: "#FF0000"
                    },
                ]
            }

        }
    },
    {
        id: 2,
        name: "Horse Care",
        slug: "horse-care",
        sections: {
            hero: {
                name: "hero-horse-care",
                subtype: "regular",
                content: [
                    {
                        img: "/DATA/IMG/page-horse_care/hero_img_horse_care@2x.jpg",
                        video: "url",
                        text: "Horse Care<br>&amp; Safety",
                    },
                ]
            },
            imgPlusContent: {
                name: "horses-riders-1st",
                type: "header-img-text",
                content: {
                    header: "Horses &amp;<br>Riders<br>1/st",
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Les condius praelintiu que cotis. Lacerfes omnem patus, virmili ssenequi precon sicus hoculegeris actem te, fora estam opopubl icaustrum talicae id se oc, conihil issiliam prartem tus.</p>",
                    img: "page-horse-care/horse_care.jpg"
                }
            },
            video: {
                name: "1st-in-horse-care",
                content: {
                    video: "url",
                    headline: "1st in horse care<br>2/4"
                }
            },
            venuesSummary: {
                name: "venues-summary",
                intro: {
                    header: "Four<br>Legendary<br>Venues",
                    subHeader: "Visit 1/st venues"
                },
                content: [
                    {
                        name: "Golden Gate Fields",
                        img: "url",
                        logo: "url",
                        color: "#F6D762"
                    },
                    {
                        name: "Santa Anita Park",
                        img: "url",
                        logo: "url",
                        color: "#05F3B0"
                    },
                    {
                        name: "Gulfstream Park",
                        img: "url",
                        logo: "url",
                        color: "#31CDEA"
                    },
                    {
                        name: "Golden Gate Fields",
                        img: "url",
                        logo: "url",
                        color: "#FF0000"
                    },
                ]
            },
            parallaxImg: {
                name: "Tired Jockey",
                content: {
                    img: "url"
                }
            }
        }
    },
    {
        id: 3,
        name: "Venues",
        slug: "venues",
        sections: {
            hero: {
                name: "venues",
                subtype: "regular",
                content: [
                    {
                        img: "/DATA/IMG/page-venues/hero_img_venues@2x.jpg",
                        video: "url",
                        text: "Our<br>&amp; Safety",
                    },
                ]
            },
            imgPlusContent: {
                name: "horses-riders-1st",
                type: "header-img-text",
                content: {
                    header: "Horses &amp;<br>Riders<br>1/st",
                    text: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.Les condius praelintiu que cotis. Lacerfes omnem patus, virmili ssenequi precon sicus hoculegeris actem te, fora estam opopubl icaustrum talicae id se oc, conihil issiliam prartem tus.</p>",
                    img: "/DATA/IMG/page-horse_care/img_horses_riders.jpg"
                }
            },
            video: {
                name: "1st-in-horse-care",
                content: {
                    video: "url",
                    headline: "1st in horse care<br>2/4"
                }
            },
            venuesSummary: {
                name: "venues-summary",
                intro: {
                    header: "Four<br>Legendary<br>Venues",
                    subHeader: "Visit 1/st venues"
                },
                content: [
                    {
                        name: "Golden Gate Fields",
                        img: "url",
                        logo: "url",
                        color: "#F6D762"
                    },
                    {
                        name: "Santa Anita Park",
                        img: "url",
                        logo: "url",
                        color: "#05F3B0"
                    },
                    {
                        name: "Gulfstream Park",
                        img: "url",
                        logo: "url",
                        color: "#31CDEA"
                    },
                    {
                        name: "Golden Gate Fields",
                        img: "url",
                        logo: "url",
                        color: "#FF0000"
                    },
                ]
            },
            parallaxImg: {
                name: "Tired Jockey",
                content: {
                    img: "url"
                }
            }
        }
    },
    {
        id: 4,
        name: "Events",
        slug: "events",
        sections: {
            hero: {
                name: "events",
                subtype: "regular",
                content: [
                    {
                        img: "/DATA/IMG/page-events/hero_img_events@2x.jpg",
                        video: "url",
                        text: "Our<br>&amp; Safety",
                    },
                ]
            },
        }
    },
    {
        id: 5,
        name: "Technology",
        slug: "tech",
        sections: {
            hero: {
                name: "technology",
                subtype: "regular",
                content: [
                    {
                        img: "/DATA/IMG/page-technology/hero_img_technology@2x.jpg",
                        video: "url",
                        text: "1/st<br>technology",
                    },
                ]
            },
        }
    },
    {
        id: 6,
        name: "About",
        slug: "about",
        sections: {
            hero: {
                name: "about",
                subtype: "regular",
                content: [
                    {
                        img: "/DATA/IMG/page-about/hero_img_about@2x.jpg",
                        video: "url",
                        text: "Our<br>&amp; Safety",
                    },
                ]
            },
        }
    },
    {
        id: 7,
        name: "Contact",
        slug: "contact",
        sections: {
            hero: {
                name: "contact",
                subtype: "regular",
                content: [
                    {
                        img: "page-contact/hero.jpg",
                        video: "url",
                        text: null,
                    },
                ]
            },
        }
    },
]