import React, {useEffect} from "react"
import "../index.scss"
import Helmet from 'react-helmet'

import animationData from "../DATA/animationData"
import { motion, AnimatePresence } from "framer-motion"

import { ParallaxProvider } from "react-scroll-parallax"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

function Layout ({ children, location }){

  useEffect(() => {
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "46vhkoac4t");
  },[])

  return (
    <>

      <Header />
      
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={location.pathname}
          initial={animationData.pageAnimation.initial}
          animate={animationData.pageAnimation.active}
          exit={animationData.pageAnimation.exit}
          className="page-animator"
        >
          <ParallaxProvider>
            {children}
          </ParallaxProvider>
          <Footer />
        </motion.div>
      </AnimatePresence>

    </>
  )
}

export default Layout
