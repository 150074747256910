import React from "react"
import "./style.scss";
import { useInView } from "react-intersection-observer"
import {Link} from "gatsby"

import { footerData } from "../../DATA/pageData"
import { graphql, useStaticQuery } from "gatsby"

function Footer(props) {
  
  const [ref, inView] = useInView({ triggerOnce: true })

  const data = useStaticQuery(graphql`
	{
		contentfulGeneral(title: {eq: "1/ST"}) {
			id
			copy
			secondaryNavigation {
				id
				title
				url
				external
			}
		}
	}
  `)
  const naviItems = data.contentfulGeneral.secondaryNavigation.map(prepNavi)

  function prepNavi(item, index) {
  	if(data.contentfulGeneral.secondaryNavigation.length === index + 2) {
  		if(data.contentfulGeneral.secondaryNavigation[index].external) {
	  		return <li key={item.id} className="navi-item"><span className="line"></span><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a></li>
  		}
  		return <li key={item.id} className="navi-item"><span className="line"></span><Link to={item.url}>{item.title}</Link></li>
  	}
	if(data.contentfulGeneral.secondaryNavigation[index].external) {
		return (
			<li key={item.id} className="navi-item"><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a></li>
		)
	}
  	return (
  		<li key={item.id} className="navi-item"><Link to={item.url}>{item.title}</Link></li>
  	)
  }

  return (
	<footer id="master-footer" ref={ref} className={["footer", props.theme, inView ? "is-inview" : null].join(" ")}>
		<div className="decorative-arrow up white"></div>
		<div className="footer-navi-wrap">
			<div className="footer-logo">
				<div className="footer-logo-positioner">
					<div className="footer-logo-sizer">
		      			<img src={footerData.logoSrc} className={["img-contain", "lazyload", "fade", inView ? "lazyloaded" : null].join(" ")} alt="1/ST Logo" />
	      			</div>
      			</div>
	  		</div>
	  	
		  	<ul className="footer-navi">
		  		<li className="navi-item navi-item-copy">{data.contentfulGeneral.copy}</li>
		  		{naviItems}
	  		</ul>

	  	</div>

	</footer>
  );
}

export default Footer
