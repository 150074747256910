import React from 'react'
import './style.scss'
import animationData from "../../../DATA/animationData"
import { motion } from "framer-motion"
import { useInView } from 'react-intersection-observer'

function BarTop(props) {
	// const [hey,setHey] = useState(true)

  const [ref, inView] = useInView({ triggerOnce: true })

	// General Animation Duration
	const baseDelay = 500
	const baseDelayStep = 150

  return (
		<motion.div 
			id="bar-top"
			ref={ref}
			className={`bar c0 ${inView ? "is-inview" : null}`}
			variants={animationData.stagger}
		>
			<div className="icons">
				<a href="https://1st.com" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-1st active-dip"></div></a>
				<a href="https://www.santaanita.com/" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-santaanita active-dip"></div></a>
				<a href="https://www.laurelpark.com/" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-maryland active-dip"></div></a>
				<a href="https://goldengatefields.com/" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-goldengate active-dip"></div></a>
				<a href="https://gulfstreampark.com/" aria-label='Racetrack link' target="_blank" rel="noreferrer noopener" className="hover-trigger nostrike"><div className="icon icon-gulfstream active-dip"></div></a>
			</div>
		</motion.div>
  )
}

export default BarTop