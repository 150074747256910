import React, { memo, useState } from "react"
import "./style.scss";

import { useInView } from "react-intersection-observer"
import { Link } from "gatsby"

import SocialIcons from "../../components/SocialIcons/SocialIcons"

import Typewriter from "typewriter-effect"
import Racetrack from "../../components/Racetrack/Racetrack"

import animationData from "../../DATA/animationData"
import { motion } from "framer-motion"
import { headerData } from "../../DATA/pageData"

import { graphql, useStaticQuery } from "gatsby"
import BarTop from "./BarTop";


function Header(props) {

  const [ref, inView] = useInView({ triggerOnce: true })
  const [visibility, setVisibility] = useState(false)
  const [hovering, setHovering] = useState(false)

  const data = useStaticQuery(graphql`
  {
	  contentfulGeneral(title: {eq: "1/ST"}) {
		  id
		  primaryNavigation {
			  id
			  title
			  url
			  external
		  }
	  }
  }
`)

const naviItems = data.contentfulGeneral.primaryNavigation.map(prepNavi)

  function prepNavi(item, index) {

	if(item.external) {
		return (

	  		<motion.li 
	          key={item.id}
	          variants={animationData.fadeInUpFaster}
	          className="navi-item"
			  		onMouseOver={() => (setHovering(true))}
			  		onFocus={() => (setHovering(true))}
	          onMouseLeave={() => (setHovering(false))}
			  >
	  			<a href={item.url} target="_blank" rel="noopener noreferrer" onClick={() => setVisibility(!visibility)} >
	  				<Typewriter
					  onInit={(typewriter) => {
					    typewriter
					    .typeString((index + 1).toString())
					    .pauseFor(index * 80)
					    .deleteAll()
					    .typeString(item.title)
					    .start();
					  }}
				   	  options={{
					  	delay: 20
					  }}
					/>
	  			</a>
	  			{hovering ?
	  				<span className="racetrack-navi-positioner"><Racetrack color="#2b2e34" /></span>
	  				: null
	  			}
	   		</motion.li>
	  	)
	}
  	return (

  		<motion.li 
          key={item.id}
          variants={animationData.fadeInUpFaster}
          className="navi-item"
          onMouseOver={() => (setHovering(true))}
          onFocus={() => (setHovering(true))}
          onMouseLeave={() => (setHovering(false))}
          onBlur={() => (setHovering(false))}
		  >
			<Link to={item.url ? item.url : "/"} onClick={() => setVisibility(!visibility)} >
				<Typewriter
				onInit={(typewriter) => {
					typewriter
					.typeString((index + 1).toString())
					.pauseFor(index * 80)
					.deleteAll()
					.typeString(item.title)
					.start();
				}}
				options={{
					delay: 20
				}}
				/>
			</Link>
  			{hovering ?
  				<span className="racetrack-navi-positioner"><Racetrack color="#2b2e34" /></span>
  				: null
  			}
   		</motion.li>
  	)

  }

  return (
		<>
			<BarTop onMobile={false} />
			<header id="master-header" ref={ref} className={["header", props.theme, inView ? "is-inview" : null].join(" ")}>
				<motion.div role="navigation" exit={{ opacity: 0}} initial="initial" animate="animate" className={["header-navi-wrap animate-in-from-top delay-1000ms", visibility ? "menu-open" : null].join(" ")}>

					<button id="master-header-btn" className="header-logo" onClick={() => setVisibility(!visibility)} onKeyDown={() => setVisibility(!visibility)} >
						<div id="main-hamburger" className="hamburger hamburger--close1">
								<div className="hamburger__icon">
									<div className="hamburger__line hamburger__line--1"></div>
									<div className="hamburger__line hamburger__line--2"></div>
									<div className="hamburger__line hamburger__line--3"></div>
								</div>
						</div>
								<ul className="header-navi fake">
									{/* Empty on purpose, to make it behave like actual list items, nearby element height based off of this */}
									<li><span>&nbsp;</span></li>
									<li><span>&nbsp;</span></li>
								</ul>
						<div className="header-logo-positioner">
							<div className="header-logo-sizer">
										<img src={headerData.logoSrc} className={["img-contain", "lazyload", "fade", inView ? "lazyloaded" : null].join(" ")} alt="1/ST Logo" />
									</div>
								</div>
						</button>
					
						{visibility ?
							<motion.ul variants={animationData.stagger} className="header-navi">
								{naviItems}
							<motion.li 
										variants={animationData.fadeInUp}
										className="navi-item">
								<SocialIcons />
							</motion.li>
							</motion.ul>
							: null
						}

					</motion.div>

			</header>
		</>
  )
}

export default memo(Header);